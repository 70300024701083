import React, { useState, useEffect, useRef, useContext } from "react"
import Styles from "../styles/Contact.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TextareaAutosize from 'react-textarea-autosize';
import axios from "axios";
import { ToastContext } from '../context/ToastContext';
import GeneralButton from "../components/atoms/GeneralButton";
import { ContactAPIURL } from "../constant/URL"
import { emailReg } from "../constant/RegExp"
import { BACK_BUTTON_STR } from "../constant/const";
import client from "../apis/client";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";

const ContactPage = () => {

    const headerOption = {
        headerTitle: "ご意見・お問い合わせ",
        leftIcon: BACK_BUTTON_STR,
    }
    const { addToast } = useContext( ToastContext );
    const [ email, setEmail ] = useState( "" )
    const [ content, setContent ] = useState( "" )
    const [ uploading, setUploading ] = useState( false )
    const isLoggedIn = useIsLoggedIn()


    const textarea = useRef<HTMLTextAreaElement>( null );


    useEffect( () => {
        componentDidMount()
        textarea.current && textarea.current.focus()
    }, [] )

    const componentDidMount = async () => {
        const userID = localStorage.getItem( "userID" )
        if ( !userID ) return
        const response = await client.get( `/api/users/me/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } );
        const email = await response.data.email;
        setEmail( email )
    }

    const submit = async () => {
        if ( uploading ) return
        if ( !content ) {
            addToast( { text: "内容が無いようです", type: "warn", position: 'center' } )
            return
        }
        if ( email !== "" && !emailReg.test( email ) ) {
            // alert( "有効なメールアドレスではありません" )
            addToast( { text: "有効なメールアドレスではありません", type: "warn", position: 'center' } )
            return
        }
        const data = {
            email,
            content,
            userID: localStorage.getItem( "userID" ) || "0"
        }
        const jsonData = JSON.stringify( data )
        setUploading( true )
        console.log( "client", client )
        await client.post( ContactAPIURL, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            withCredentials: false
        } )
            .then( res => {
                addToast( { text: "送信完了しました\nご協力ありがとうございます", type: "success", position: 'center' } )
                setContent( "" )
            } )
            .catch( error => {
                addToast( { text: "送信に失敗しました", type: "error", position: 'center' } )
                console.log( error )
            } );
        setUploading( false )
    }

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="Contact" />
                <div className={ Styles.container }>
                    {/* <p>お手数ですが、下記のメールアドレスまでご連絡ください。</p>
				<br />
				<p>club.cloud.jp@gmail.com</p> */}
                    {/* <iframe
					src="https://docs.google.com/forms/d/e/1FAIpQLSfaMlTe1tkC_MF8jn6Bz4xJm5m9z3YMOro9cxuq2RXsIU3h7w/viewform?embedded=true"
					style={ { width: "100%" } }
					width="640"
					height="594"
					frameborder="0"
					marginheight="0"
					marginwidth="0">
					読み込んでいます…
				</iframe> */}
                    <div style={ {
                        width: "100%",
                        display: ( typeof window !== 'undefined' && isLoggedIn ) ?
                            "none" :
                            "block"
                    } }>
                        <p className={ Styles.label }>
                            返信用Emailアドレス
                        </p>
                        <input
                            // style={ { width: "100%" } }
                            type="email"
                            name="email"
                            className={ Styles.email }
                            value={ email }
                            onChange={ e => setEmail( e.target.value ) } />
                    </div>

                    {/* <InputInfoItem label="返信用Emailアドレス" name="email" value={ email } onChange={ ( name, value ) => setEmail( value ) } /> */ }
                    <p className={ Styles.label }>
                        内容
                    </p>
                    <TextareaAutosize
                        onChange={ e => setContent( e.target.value ) }
                        name="content"
                        placeholder="ささいなご意見やお問い合わせでも結構です"
                        autoFocus={ true }
                        inputRef={ textarea }
                        // inputRef={ tag => ( textarea.current = tag ) }
                        value={ content }
                        maxRows={ 10 }
                        minRows={ 2 }
                        className={ Styles.textarea }>
                    </TextareaAutosize>
                    <GeneralButton
                        onClick={ () => submit() }
                        loading={ uploading }
                        title="送信"
                        style={ { width: 200, padding: "8px 24px", marginBottom: "16px", alignSelf: 'flex-end', } } />
                </div>
            </>
        </Layout>
    )
}

export default ContactPage
